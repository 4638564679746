import "../App.css";
import { useState } from "react";
import { getSteigerungsMod, steigern } from "../scripts/steigerungsCalculator";

function Steigern() {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(1);
  const [selectedSpalte, setSelectedSpalte] = useState("A+");
  const [apKosten, setApKosten] = useState(0);
  const width = window.innerWidth;

  const onSubmit = (e) => {
    e.preventDefault();
    setApKosten(
      steigern(
        min,
        max,
        selectedSpalte,
        getSteigerungsMod(gutesGedächtnisChecked, eidetischesGedächtnisChecked)
      )
    );
  };

  const onMinChange = (e) => {
    const newMinValue = parseInt(e.target.value, 10);
    if (newMinValue >= max && width > 768) {
      setMin(max);
      setMax(max + 1);
    } else {
      setMin(newMinValue);
    }
  };

  const onMaxChange = (e) => {
    const newMaxValue = parseInt(e.target.value, 10);
    if (newMaxValue <= min && width > 768) {
      setMax(min);
      setMin(max - 1);
    } else {
      setMax(newMaxValue);
    }
  };

  const handleSpalteChange = (event) => {
    setSelectedSpalte(event.target.value);
  };

  const [gutesGedächtnisChecked, setGutesGedächtnisChecked] = useState(false);

  const [eidetischesGedächtnisChecked, setEidetischesGedächtnisChecked] =
    useState(false);

  const handleGutesGedächtnisChange = (event) => {
    setGutesGedächtnisChecked(event.target.checked);
  };
  const handleEidetischesGedächtnisChange = (event) => {
    setEidetischesGedächtnisChecked(event.target.checked);
  };

  return (
    <div className="app">
      <h2 className="title">Steigerungskosten Rechner</h2>
      <div className="steigerung">
        <form onSubmit={onSubmit} className="steigerungs-form">
          <label for="start">Startwert</label>
          <input
            type={"number"}
            step={1}
            min={-3}
            max={99}
            name="Von"
            id="start"
            onChange={onMinChange}
            value={min}
            className="steigerungs-input"
          ></input>
          <label for="end">Zielwert</label>
          <input
            type={"number"}
            step={1}
            min={-3}
            max={99}
            name="Zu"
            id="end"
            onChange={onMaxChange}
            value={max}
            className="steigerungs-input"
          ></input>
          <label for="spalte">Spalte</label>
          <select
            value={selectedSpalte}
            onChange={handleSpalteChange}
            className="steigerungs-input"
            id="spalte"
          >
            <option value={"A+"}>A*</option>
            <option value={"A"}>A</option>
            <option value={"B"}>B</option>
            <option value={"C"}>C</option>
            <option value={"D"}>D</option>
            <option value={"E"}>E</option>
            <option value={"F"}>F</option>
            <option value={"G"}>G</option>
            <option value={"H"}>H</option>
          </select>

          <div className="steigerungs-checkbox-container">
            <input
              type="checkbox"
              id="eidetisch"
              onChange={handleEidetischesGedächtnisChange}
              className="steigerungs-checkbox"
            ></input>
            <label for="eidetisch" className="steigerungs-checkbox-label">
              Eidetisches Gedächtnis
            </label>
          </div>

          <div className="steigerungs-checkbox-container">
            <input
              type="checkbox"
              id="gut"
              onChange={handleGutesGedächtnisChange}
              className="steigerungs-checkbox"
            ></input>
            <label for="gut" className="steigerungs-checkbox-label">
              Gutes Gedächtnis
            </label>
          </div>

          <input
            type={"submit"}
            value={"Berechnen"}
            className="steigerungs-input steigerungs-submit"
          ></input>
        </form>
        <h3>AP Preis:</h3>
        <h4>{apKosten}</h4>
      </div>
    </div>
  );
}

export default Steigern;
