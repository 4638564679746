import React, { useState } from "react";

function NumberEditor(props) {
  const [value, setValue] = useState(props.value);

  const getValue = () => {
    return value;
  };

  const getInputNode = () => {
    return node;
  };

  const handleChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (isNaN(value)) {
      return;
    }

    setValue(value, () => {
      props.onCommit();
    });
  };

  let node;
  return (
    <input
      ref={(input) => (node = input)}
      type="number"
      value={value}
      onChange={handleChange}
    />
  );
}

export default NumberEditor;
