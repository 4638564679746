import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HeldenView from "./components/held";
import Steigern from "./components/steigern";
import Zauber from "./components/zauberSpoMod/Zauber";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  {
    path: "/steigern",
    element: <Steigern />,
  },
  {
    path: "/held",
    element: <HeldenView />,
  },
  {
    path: "/zauber",
    element: <Zauber />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
