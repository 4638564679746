export function steigern(startWert, zielWert, spalte, steigerungsMod) {
  const steigerungskostenAp = [
    5, 1, 1, 1, 2, 4, 5, 6, 8, 9, 11, 12, 14, 15, 17, 19, 20, 22, 24, 25, 27,
    29, 31, 32, 34, 36, 38, 40, 42, 43, 45, 48,
  ];
  const steigerungskostenA = [
    5, 1, 2, 3, 4, 6, 7, 8, 10, 11, 13, 14, 16, 17, 19, 21, 22, 24, 26, 27, 29,
    31, 33, 34, 36, 38, 40, 42, 44, 45, 47, 50,
  ];
  const steigerungskostenB = [
    10, 2, 4, 6, 8, 11, 14, 17, 19, 22, 25, 28, 32, 35, 38, 41, 45, 48, 51, 55,
    58, 62, 65, 69, 73, 76, 80, 84, 87, 91, 95, 100,
  ];
  const steigerungskostenC = [
    15, 2, 6, 9, 13, 17, 21, 25, 29, 34, 38, 43, 47, 51, 55, 60, 65, 70, 75, 80,
    85, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 150,
  ];
  const steigerungskostenD = [
    20, 3, 7, 12, 17, 22, 27, 33, 39, 45, 50, 55, 65, 70, 75, 85, 90, 95, 105,
    110, 115, 125, 130, 140, 145, 150, 160, 165, 170, 180, 190, 200,
  ];
  const steigerungskostenE = [
    25, 4, 9, 15, 21, 28, 34, 41, 48, 55, 65, 70, 80, 85, 95, 105, 110, 120,
    130, 135, 145, 155, 165, 170, 180, 190, 200, 210, 220, 230, 240, 250,
  ];
  const steigerungskostenF = [
    40, 6, 14, 22, 32, 41, 50, 60, 75, 85, 95, 105, 120, 130, 140, 155, 165,
    180, 195, 210, 220, 230, 250, 260, 270, 290, 300, 310, 330, 340, 350, 375,
  ];
  const steigerungskostenG = [
    50, 8, 18, 30, 42, 55, 70, 58, 95, 110, 125, 140, 160, 175, 190, 210, 220,
    240, 260, 270, 290, 310, 330, 340, 360, 380, 400, 420, 440, 460, 480, 500,
  ];
  const steigerungskostenH = [
    100, 16, 35, 60, 85, 110, 140, 165, 195, 220, 250, 280, 320, 350, 380, 410,
    450, 480, 510, 550, 580, 620, 650, 690, 720, 760, 800, 830, 870, 910, 950,
    1000,
  ];

  let steigerungsKosten;

  // Spalte wählen
  switch (spalte) {
    case "A+": {
      steigerungsKosten = steigerungskostenAp;
      break;
    }
    case "A": {
      steigerungsKosten = steigerungskostenA;
      break;
    }
    case "B": {
      steigerungsKosten = steigerungskostenB;
      break;
    }
    case "C": {
      steigerungsKosten = steigerungskostenC;
      break;
    }
    case "D": {
      steigerungsKosten = steigerungskostenD;
      break;
    }
    case "E": {
      steigerungsKosten = steigerungskostenE;
      break;
    }
    case "F": {
      steigerungsKosten = steigerungskostenF;
      break;
    }
    case "G": {
      steigerungsKosten = steigerungskostenG;
      break;
    }
    case "H": {
      steigerungsKosten = steigerungskostenH;
      break;
    }
    default: {
      return -1;
    }
  }

  let apKosten = 0;



  for (let i = startWert; i < zielWert; i++) {
    if (i >= 31) {
      apKosten += getSteigerungsKosten(31, steigerungsMod);
      continue;
    }
    if (i >= 0) {
      apKosten += getSteigerungsKosten(i+1, steigerungsMod)
    } else {
      apKosten += getSteigerungsKosten(0, steigerungsMod)
    }
  }

  console.log("Ap Preis: " + apKosten);

  return apKosten;

  function getSteigerungsKosten(spalte, steigerungsMod) {
    return Math.max(1, Math.round(steigerungsKosten[spalte] * steigerungsMod));
  }
}

export function getSteigerungsMod(gutesGedächtnis, eidetischesGedächtnis) {
  let steigerungsMod = 1;

  if (gutesGedächtnis) {steigerungsMod *= 0.75;}

  if (eidetischesGedächtnis) {steigerungsMod *= 0.5;}

  console.log("gutesGedächtnis: " + gutesGedächtnis + " eidetischesGedächtnis: " + eidetischesGedächtnis)
  console.log("Steigerungsmod: " + steigerungsMod)
  return steigerungsMod;
}
