import React from 'react'
import { Form } from 'react-router-dom'

function Zauber() {
  return (
    <div>
        <Form>
            <input type='number' id='dauer'></input>
            <label for="dauer">Zauberdauer</label>
            <label for="dauer">Erzwingen</label>
            <label for="dauer">Einsparen</label>
            <label for="dauer">Zielperson</label>
            <label for="dauer">Mehrere Ziele</label>
            <label for="dauer">Reichweite/Radius</label>
            <label for="dauer">Wirkungsdauer</label>
            <label for="dauer">Wirkungsdauer (aufrechterhalten)</label>

        </Form>
    </div>
  )
}

export default Zauber