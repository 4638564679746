import React, { useEffect, useState } from "react";
import "react-data-grid/lib/styles.css";
import DataGrid, { textEditor } from "react-data-grid";

function Eigenschaft(props) {
  const columns = [
    { key: "eigenschaft", name: "Eigenschaft" },
    { key: "modifikator", name: "Modifikator", resizable: true },
    { key: "start", name: "Start", resizable: true },
    {
      key: "aktuell",
      name: "Aktuell",
      editable: true,
      resizable: true,
      editor: textEditor,
    },
  ];

  const [rows, setRows] = useState([
    {
      eigenschaft: "Mut",
      modifikator: "0",
      start: "0",
      aktuell: props.eigenschaften.mut,
    },
    {
      eigenschaft: "Klugheit",
      modifikator: "0",
      start: "0",
      aktuell: props.eigenschaften.klugheit,
    },
    {
      eigenschaft: "Intuition",
      modifikator: "0",
      start: "0",
      aktuell: props.eigenschaften.intuition,
    },
    {
      eigenschaft: "Charisma",
      modifikator: "0",
      start: "0",
      aktuell: props.eigenschaften.charisma,
    },
    {
      eigenschaft: "Fingerfertigkeit",
      modifikator: "0",
      start: "0",
      aktuell: props.eigenschaften.fingerfertigkeit,
    },
    {
      eigenschaft: "Gewandheit",
      modifikator: "0",
      start: "0",
      aktuell: props.eigenschaften.gewandheit,
    },
    {
      eigenschaft: "Konstitution",
      modifikator: "0",
      start: "0",
      aktuell: props.eigenschaften.konstitution,
    },
    {
      eigenschaft: "Körperkraft",
      modifikator: "0",
      start: "0",
      aktuell: props.eigenschaften.körperkraft,
    },
  ]);

  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [rowsOld, setRowsOld] = useState(rows);

  useEffect(() => {
    if (rowsOld !== rows) {
      props.onRowsChange(rows);
      setRowsOld(rows);
    }
  }, [rows, props, rowsOld]);

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      cellEditable={{ aktuell: true }}
      onRowsChange={setRows}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
    />
  );
}

export default Eigenschaft;
