import React, { useEffect, useState } from "react";
import Eigenschaft from "./heldenbogen/Eigenschaft";
import Basiswerte from "./heldenbogen/Basiswerte";

function HeldenView() {
  const [eigenschaften, setEigenschaften] = useState({
    mut: 1,
    klugheit: 2,
    intuition: 3,
    charisma: 4,
    fingerfertigkeit: 5,
    gewandheit: 6,
    konstitution: 7,
    körperkraft: 8,
  });

  const [basiswerte, setBasiswerte] = useState({
    lebenspunkte: 0,
    ausdauer: 1,
    astralenergie: 2,
    magieresistenz: 3,
    karmaenergie: 4,
    initiativewert: 5,
    attackewert: 6,
    paradewert: 7,
    fernkampfwert: 8,
  });

  useEffect(() => {
    setBasiswerte({
      lebenspunkte:
        (eigenschaften.konstitution +
          eigenschaften.konstitution +
          eigenschaften.körperkraft) /
        2,
      ausdauer:
        (eigenschaften.mut +
          eigenschaften.konstitution +
          eigenschaften.gewandheit) /
        2,
      astralenergie:
        (eigenschaften.mut + eigenschaften.intuition + eigenschaften.charisma) /
        2, //[+CH] (mit sonderfertigkeit) !TODO
      magieresistenz:
        (eigenschaften.mut +
          eigenschaften.klugheit +
          eigenschaften.konstitution) /
        5,
      karmaenergie: 4,
      initiativewert:
        (eigenschaften.mut +
          eigenschaften.mut +
          eigenschaften.intuition +
          eigenschaften.gewandheit) /
        5,
      attackewert:
        (eigenschaften.mut +
          eigenschaften.gewandheit +
          eigenschaften.körperkraft) /
        5,
      paradewert:
        (eigenschaften.intuition +
          eigenschaften.gewandheit +
          eigenschaften.körperkraft) /
        5,
      fernkampfwert:
        (eigenschaften.intuition +
          eigenschaften.fingerfertigkeit +
          eigenschaften.körperkraft) /
        5,
    });
  }, [eigenschaften]);

  const onEigenschaftenChange = (rows) => {
    setEigenschaften((prevState) => {
      return rows.reduce(
        (updatedState, row) => {
          updatedState[row.eigenschaft.toLowerCase()] = parseInt(row.aktuell);
          return updatedState;
        },
        { ...prevState }
      );
    });
  };

  const onBasiswerteChange = (rows) => {
    setBasiswerte((prevState) => {
      return rows.reduce(
        (updatedState, row) => {
          updatedState[row.bezeichnung.toLowerCase()] = parseInt(row.start);
          return updatedState;
        },
        { ...prevState }
      );
    });
    console.log(basiswerte)
  };

  return (
    <div>
      <Eigenschaft
        eigenschaften={eigenschaften}
        onRowsChange={onEigenschaftenChange}
      ></Eigenschaft>
      <Basiswerte
        basiswerte={basiswerte}
        onRowsChange={onBasiswerteChange}
      ></Basiswerte>
    </div>
  );
}

export default HeldenView;
