import React, { useEffect, useState } from "react";
import DataGrid, { textEditor } from "react-data-grid";
import NumberEditor from "../DataGridEditors/NumberEditor";

function Basiswerte(props) {
  const columns = [
    { key: "bezeichnung", name: "Bezeichnung" },
    { key: "start", name: "Start" },
    { key: "modifikator", name: "Modifikator", editor: NumberEditor },
    { key: "verloren", name: "Alter/pAsp" }, //Verloren durch z.B. Alter/pAsp
    { key: "gewonnen", name: "Med./Queste" }, //Gewonnen durch z.B. große Meditation/Karmale Queste
    { key: "aktuell", name: "Aktuell" },
    { key: "zukauf", name: "Zugekauft" },
    { key: "max", name: "Maximal Zugekauft" },
  ];

  const [rows, setRows] = useState([
    {
      bezeichnung: "Lebenspunkte",
      start: props.basiswerte.lebenspunkte,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
    {
      bezeichnung: "Ausdauer",
      start: props.basiswerte.ausdauer,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
    {
      bezeichnung: "Astralenergie",
      start: props.basiswerte.astralenergie,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
    {
      bezeichnung: "Magieresistenz",
      start: props.basiswerte.magieresistenz,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
    {
      bezeichnung: "Karmaenergie",
      start: props.basiswerte.karmaenergie,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
    {
      bezeichnung: "Initiativewert",
      start: props.basiswerte.initiativewert,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
    {
      bezeichnung: "Attackewert",
      start: props.basiswerte.attackewert,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
    {
      bezeichnung: "Paradewert",
      start: props.basiswerte.paradewert,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
    {
      bezeichnung: "Fernkampfwert",
      start: props.basiswerte.fernkampfwert,
      modifikator: "0",
      verloren: "0",
      gewonnen: "0",
      aktuell: "0",
      zukauf: "0",
      max: "0",
    },
  ]);

  const [rowsOld, setRowsOld] = useState(rows);

  useEffect(() => {
    /*  setRows([
      {
        bezeichnung: "Lebenspunkte",
        start: props.basiswerte.lebenspunkte,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
      {
        bezeichnung: "Ausdauer",
        start: props.basiswerte.ausdauer,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
      {
        bezeichnung: "Astralenergie",
        start: props.basiswerte.astralenergie,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
      {
        bezeichnung: "Magieresistenz",
        start: props.basiswerte.magieresistenz,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
      {
        bezeichnung: "Karmaenergie",
        start: props.basiswerte.karmaenergie,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
      {
        bezeichnung: "Initiativewert",
        start: props.basiswerte.initiativewert,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
      {
        bezeichnung: "Attackewert",
        start: props.basiswerte.attackewert,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
      {
        bezeichnung: "Paradewert",
        start: props.basiswerte.paradewert,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
      {
        bezeichnung: "Fernkampfwert",
        start: props.basiswerte.fernkampfwert,
        modifikator: "0",
        verloren: "0",
        gewonnen: "0",
        aktuell: "0",
        zukauf: "0",
        max: "0",
      },
    ]); */

    setRows((prevState) =>
      prevState.map((row, index) => ({
        ...row,
        start: props.basiswerte[Object.keys(props.basiswerte)[index]],
      }))
    );
  }, [props]);

  useEffect(() => {
    if (rowsOld !== rows) {
      props.onRowsChange(rows);
      return () => {
        setRowsOld(rows);
      };
    }
  }, [rows, props]);

  const updatedRows = rows.map((row) => {
    const { start, modifikator, verloren, gewonnen, zukauf } = row;
    const aktuell =
      Number(start) +
      Number(modifikator) -
      Number(verloren) +
      Number(gewonnen) +
      Number(zukauf);
    const max = Math.round((Number(start) + Number(modifikator)) * 1.5);
    return { ...row, aktuell: aktuell.toString(), max: max.toString() };
  });

  return <DataGrid columns={columns} rows={rows} onRowsChange={setRows} />;
}

export default Basiswerte;
