import "./App.css";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";


function App() {

  const navigate = useNavigate()

  useEffect(() => {
    navigate('/steigern')
  }, []);

  return <div>HOMEPAGE: COMING SOON</div>;
}

export default App;
